import StudentIcon from '@/components/icons/GraduateIcon.vue'
import { type Route } from '@/types/route'

export const teEnrolmentNotTabRoutes = [
  {
    path: 'te-enrolment/add',
    name: 'te-enrolment-add' as const,
    component: () =>
      import(
        '@/views/Planner/TalentEnrichment/CreateForm/CreateEnrolmentView.vue'
      ),
    meta: {
      title: 'Create T&E enrolment',
      tab: 'administration',
      permissions: ['permissions.tae_enrolment']
    }
  },
  {
    path: 'te-enrolment/:enrolmentId/courses',
    name: 'te-enrolment-details-courses' as const,
    component: () =>
      import('@/views/Planner/TalentEnrichment/Details/CoursesView.vue'),
    meta: {
      title: 'Enrolment details',
      tab: 'administration',
      permissions: ['permissions.tae_enrolment']
    }
  },
  {
    path: 'te-enrolment/:enrolmentId/students',
    name: 'te-enrolment-details-students' as const,
    component: () =>
      import('@/views/Planner/TalentEnrichment/Details/StudentsView.vue'),
    meta: {
      title: 'Enrolment details',
      tab: 'administration',
      permissions: ['permissions.tae_enrolment']
    }
  }
] satisfies Route[]

export const teEnrolmentTabRoute = {
  path: 'te-enrolment',
  name: 'te-enrolment' as const,
  component: () =>
    import('@/views/Planner/TalentEnrichment/EnrolmentsListView.vue'),
  meta: {
    title: 'T&E enrolment',
    tab: 'administration',
    label: 'Talent & Enrichment',
    iconComponent: StudentIcon
  }
} satisfies Route
